<!-- 系统设置 系统操作日志 日志纪录 -->
<template>
  <scroll-layout>
    <template slot="header">
      <ykc-warm-tip
        type="warning"
        description="温馨提示：系统日志只保留近30天的操作信息，请及时导出保存"></ykc-warm-tip>
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @clean="handleSearchClean"
        @click="handleSearchConfirm"></ykc-search>
    </template>
    <div class="motorcade-main-account">
      <ykc-table
        ref="YkcTable"
        operateFixedType="right"
        :data="tableData"
        :title="tableTitle"
        :columns="tableColumns"
        :operateButtons="tableOperateButtons.filter(item => item.enabled())">
        <template #headerButtons>
          <div class="table-header-button">
            <ykc-button v-rbac="'sys:log:export'" type="plain" @click="doExportFile">
              导出
            </ykc-button>
          </div>
        </template>
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :page-size.sync="pageInfo.pageSize"
          :current-page.sync="pageInfo.pageIndex"
          @size-change="handlePaginationSizeChange"
          @current-change="handlePaginationCurrentChange"></ykc-pagination>
      </ykc-table>
    </div>
    <ykc-operation-dialog
      :hasContentBorder="true"
      :show.sync="showDialog"
      title="日志详情"
      size="large"
      :show-footer="false">
      <template slot="content">
        <div class="dialog-operation-content">
          {{ logDetail }}
        </div>
      </template>
    </ykc-operation-dialog>
  </scroll-layout>
</template>

<script>
  import { sysLog } from '@/service/apis';
  import { code, getLastTime, offlineExport } from '@/utils';

  export default {
    name: 'operationLogList',
    data() {
      return {
        showDialog: false,
        logDetail: '',
        tableData: [],
        tableTitle: '日志列表',
        tableColumns: [
          { label: '操作账号', prop: 'opAccount', minWidth: '60px' },
          {
            label: '操作时间',
            prop: 'opTime',
            minWidth: '60px',
          },
          { label: '接口路径', prop: 'opMenuPathName', minWidth: '100px' },
          { label: '操作日志', prop: 'logDetail', minWidth: '200px' },
        ],
        tableOperateButtons: [
          {
            enabled: () => code('sys:log:detail'),
            id: '2',
            text: '详情',
            handleClick: (btn, { row }) => {
              this.openDetail(row.id);
            },
          },
        ],
        pageInfo: {
          pageIndex: 1,
          pageSize: 10,
        },
        searchParams: {
          operationTime: [],
          opAccount: '',
          startTime: '',
          endTime: '',
        },
      };
    },
    computed: {
      searchData() {
        return [
          {
            comName: 'YkcDatePicker',
            key: 'operationTime',
            label: '操作时间',
          },
          {
            comName: 'YkcInput',
            key: 'opAccount',
            label: '操作账号',
            placeholder: '请输入操作账号',
          },
        ];
      },
    },
    mounted() {
      const startDate = getLastTime('yyyy-MM-dd', 30);
      const endDate = getLastTime('yyyy-MM-dd', 0);

      this.searchParams.operationTime = [startDate, endDate];
      this.searchParams.startTime = startDate;
      this.searchParams.endTime = endDate;

      this.searchTableList();
    },
    methods: {
      /** 根据条件查询数据 */
      searchTableList() {
        const reqParams = {
          ...this.searchParams,
          current: this.pageInfo.pageIndex,
          size: this.pageInfo.pageSize,
        };
        sysLog
          .queryLogList(reqParams)
          .then(res => {
            this.tableData = res?.records || [];
            this.pageInfo.total = res?.total;
          })
          .catch(() => {
            this.tableData = [];
            this.pageInfo.total = 0;
          });
      },
      /** 点击查询按钮 */
      handleSearchConfirm(form) {
        this.pageInfo.pageIndex = 1;

        Object.assign(this.searchParams, form);

        if (form.operationTime.length > 0) {
          [this.searchParams.startTime, this.searchParams.endTime] = form.operationTime;
        }

        this.searchTableList();
      },
      /** 点击清空查询按钮 */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);

        const startDate = getLastTime('yyyy-MM-dd', 30);
        const endDate = getLastTime('yyyy-MM-dd', 0);
        this.searchParams.operationTime = [startDate, endDate];
        this.searchParams.startTime = startDate;
        this.searchParams.endTime = endDate;
      },
      /** 分页 change 事件监听器 */
      handlePaginationCurrentChange(current) {
        this.pageInfo.pageIndex = current;
        this.searchTableList();
      },
      /** 分页 size  change  事件监听器 */
      handlePaginationSizeChange(size) {
        this.pageInfo.pageIndex = 1;
        this.pageInfo.pageSize = size;
        this.searchTableList();
      },
      /** 导出文件 */
      doExportFile() {
        this.searchParams.startTime = '';
        this.searchParams.endTime = '';
        if (this.searchParams.operationTime.length > 0) {
          [this.searchParams.startTime, this.searchParams.endTime] =
            this.searchParams.operationTime;
        }
        offlineExport(
          {
            downloadType: 'log',
            jsonNode: {
              opAccount: this.searchParams.opAccount || '',
              startTime: this.searchParams.startTime || '',
              endTime: this.searchParams.endTime || '',
            },
          },
          this.pageInfo.total
        );
      },
      // 详情弹窗
      openDetail(id) {
        const reqParams = {
          id,
        };
        sysLog.queryLogDetail(reqParams).then(res => {
          this.logDetail = res.logDetail;
          this.showDialog = true;
        });
      },
    },
  };
</script>
<style>
  .dialog-operation-content {
    padding: 7px 15px 7px 7px;
    white-space: pre-wrap;
    box-sizing: border-box;
    max-height: 450px;
  }
</style>
